import { sanityConfig } from "@libry-content/sanity-common";
import { ClientConfig, createClient, createImageUrlBuilder, createPreviewSubscriptionHook } from "next-sanity";

const frontendSanityConfig: ClientConfig = {
  ...sanityConfig,
  useCdn: process.env.NODE_ENV === "production",
};

export const imageUrlBuilder = (source: any) => createImageUrlBuilder(frontendSanityConfig).image(source);

export const usePreviewSubscription = createPreviewSubscriptionHook(frontendSanityConfig);

export const sanityClient = createClient(frontendSanityConfig);
