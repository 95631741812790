import { Link } from "@biblioteksentralen/js-utils";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { useSanityAuth } from "../sanityAuth/useSanityAuth";
import { usePreviewContext } from "./previewContext";

function PreviewBanner() {
  const context = usePreviewContext();
  const authStatus = useSanityAuth();

  if (!context.isOn) {
    return null;
  }

  return (
    <Flex
      justifyContent="center"
      backgroundColor="hsl(0, 100%, 38%, 0.6)"
      color="white"
      fontSize="0.8rem"
      padding="0.25rem"
      fontWeight="600"
      position="sticky"
      top="0"
      zIndex="1000"
    >
      {authStatus.isAuthenticated ? (
        <Text>Forhåndsvisning</Text>
      ) : (
        <Text>
          Du må{" "}
          <Link inheritColor href="/cms/">
            logge inn
          </Link>{" "}
          for forhåndsvisning
        </Text>
      )}
    </Flex>
  );
}

export default PreviewBanner;
